
import { defineComponent } from 'vue';

import { useHasSlot } from '@/composables';

export default defineComponent({
	props: {
		headline: {
			type: String,
			default: ''
		},
		subHeadline: {
			type: String,
			default: ''
		},

		landing: Boolean
	},

	setup(props, { slots }) {
		return {
			hasHeadline: useHasSlot('headline', slots, hasSlot => hasSlot || !!props.headline),
			hasColumns: useHasSlot('columns', slots)
		};
	}
});
