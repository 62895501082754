<template>
	<div class="question-structure" :class="{ 'question-structure--landing': landing }">
		<h2 v-if="hasHeadline" class="question-structure__headline">
			<slot name="headline">
				{{ headline }}

				<small v-if="subHeadline">{{ subHeadline }}</small>
			</slot>
		</h2>

		<div v-if="hasColumns" class="question-structure__columns">
			<slot name="columns" />
		</div>

		<slot v-else />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useHasSlot } from '@/composables';

export default defineComponent({
	props: {
		headline: {
			type: String,
			default: ''
		},
		subHeadline: {
			type: String,
			default: ''
		},

		landing: Boolean
	},

	setup(props, { slots }) {
		return {
			hasHeadline: useHasSlot('headline', slots, hasSlot => hasSlot || !!props.headline),
			hasColumns: useHasSlot('columns', slots)
		};
	}
});
</script>

<style lang="scss">
.question-structure {
	$self: &;

	margin-top: 4.4vh;

	@include under-hd {
		margin-top: 0;
	}

	@include max-phablet {
		width: 100%;
	}

	&__headline {
		@include font('headline');

		max-width: 35vw;
		margin-right: auto;
		margin-bottom: 5.3rem;
		margin-left: auto;
		padding: 0 15px;
		position: relative;
		z-index: $z_index_over_content;
		color: $color_cyan;

		@include under-hd {
			max-width: 50vw;
			margin-bottom: 3.4rem;
		}

		@include max-laptop {
			max-width: 60vw;
		}

		@include tablet-portrait {
			margin-bottom: 5vh;
		}

		@include max-phablet {
			max-width: 100%;
			margin-bottom: 32px;
			line-height: (32 / 24);
		}

		small {
			@include font-roboto;

			display: block;
			margin-top: 5px;
			font-size: 2.1rem;
			font-weight: normal;
			line-height: (24 / 21);
		}

		.question-structure--date & {
			@include max-mobile {
				margin-bottom: 20px;
			}
		}

		.final-result & {
			margin-bottom: 4.1rem;
			color: white;

			@include under-hd {
				margin-bottom: 3.4rem;
			}
		}
	}

	&__columns {
		@include flex-start-center;

		position: relative;
		z-index: $z_index_over_content;

		@include max-phablet {
			max-width: 100%;
			padding: 0 31px;
		}

		@include max-mobile {
			padding: 0 24px;
		}

		> div + div {
			margin-left: 20px;

			@include tablet-portrait {
				margin-left: 24px;
			}

			@include max-phablet {
				margin-left: 20px;
			}
		}

		.boolean-question {
			width: 21.1rem;

			@include tablet-portrait {
				width: 211px;
			}

			@include max-phablet {
				width: 214px;
				max-width: 50%;
			}

			@include bp(500) {
				width: 50%;
			}

			.base-button {
				width: 100%;
				min-width: 100px;
				text-transform: uppercase;
			}
		}

		#{$self}--landing & > div + div {
			margin-left: 45px;

			@include tablet-portrait {
				margin-left: 34px;
			}

			@include max-phablet {
				margin-left: 22px;
			}
		}
	}
}
</style>
