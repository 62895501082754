
import { defineComponent } from 'vue';

import QuestionStructure from '@/components/questions/QuestionStructure.vue';
import { useGetImage } from '@/composables';

export default defineComponent({
	components: {
		QuestionStructure
	},

	setup() {
		const { getQuestionsImage } = useGetImage();

		return {
			images: {
				cat: getQuestionsImage('question-cat'),
				dog: getQuestionsImage('question-dog')
			}
		};
	},

	methods: {
		onClickBack() {
			this.$router.back();
		},

		onPetSelect(pet: 'cat' | 'dog') {
			this.$router.push({ name: `${pet}Questions` });
		}
	}
});
