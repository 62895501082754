<template>
	<section class="questions">
		<question-structure landing headline="Twój pupil to:">
			<template #columns>
				<div class="questions__column--left">
					<div class="questions__column-image">
						<VLazyImage
							:src="images.cat"
							alt="Cat"
							class="questions__animal-img"
							width="226"
							height="223"
						/>
					</div>

					<base-button @click="onPetSelect('cat')">
						KOT
					</base-button>
				</div>

				<div class="questions__column--right">
					<div class="questions__column-image">
						<VLazyImage
							:src="images.dog"
							alt="Dog"
							class="questions__animal-img"
							width="226"
							height="223"
						/>
					</div>

					<base-button @click="onPetSelect('dog')">
						PIES
					</base-button>
				</div>
			</template>
		</question-structure>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import QuestionStructure from '@/components/questions/QuestionStructure.vue';
import { useGetImage } from '@/composables';

export default defineComponent({
	components: {
		QuestionStructure
	},

	setup() {
		const { getQuestionsImage } = useGetImage();

		return {
			images: {
				cat: getQuestionsImage('question-cat'),
				dog: getQuestionsImage('question-dog')
			}
		};
	},

	methods: {
		onClickBack() {
			this.$router.back();
		},

		onPetSelect(pet: 'cat' | 'dog') {
			this.$router.push({ name: `${pet}Questions` });
		}
	}
});
</script>
;

<style lang="scss">
.questions {
	@include question-wrapper;

	@include max-phablet {
		padding-bottom: 20px;
	}

	&__column {
		max-width: 211px;

		@include max-phablet {
			min-width: 130px;
			max-width: 130px;
		}
	}

	&__animal-img {
		display: block;
		min-width: 225px;
		height: auto;
		margin: 0 auto;
		margin-bottom: 2.2rem;
		position: relative;

		@include max-desktop {
			min-width: 100px;
			position: static;
			left: 0;
		}

		@include tablet-portrait {
			width: 107%;
			max-width: 107%;
			margin-bottom: 23px;
			position: relative;
			left: -3%;
		}

		@include max-phablet {
			width: 127px;
			max-width: 100%;
			margin-bottom: 14px;
			position: static;
			left: 0;
		}

		@include max-mobile {
			width: 100%;
			max-width: 100%;
			margin-bottom: 16px;
		}
	}
}
</style>
